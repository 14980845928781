import axios from 'axios';

export default {
  uploadFile(payload) {
    const form = new FormData();
    form.append('file', payload.file);
    return axios.post(`${payload.url}`, form,
      {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      });
  },
};
