import Repository from '../../services/repositories/RepositoryFactory';

const UserRepository = Repository.service('users');

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async uploadFile(_, payload) {
      try {
        const response = await UserRepository.uploadFile(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};
