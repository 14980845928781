// Primevue
import PrimeVue from 'primevue/config';
import FileUpload from 'primevue/fileupload';
import InputText from 'primevue/inputtext';
import Carousel from 'primevue/carousel';
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import Vue from 'vue';

Vue.component('FileUpload', FileUpload);
Vue.component('InputText', InputText);
Vue.component('Carousel', Carousel);
Vue.component('Button', Button);
Vue.component('Toast', Toast);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.directive('tooltip', Tooltip);

Vue.use(PrimeVue);
Vue.use(ToastService);
