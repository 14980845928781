import VuePdfApp from "vue-pdf-app";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Element
import '@/plugins/element';
// eCharts
import '@/plugins/eCharts';
// primeVue
import '@/plugins/primevue';
// jsonviewer
import '@/plugins/jsonviewer';
// imageviewer
import '@/plugins/vueviewer';

Vue.config.productionTip = false;
Vue.component("vue-pdf-app", VuePdfApp);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
